/* Defulat Fonts */

@font-face {
  font-family: 'AudiType-Normal';
  src: local('AudiType-Normal'), url('../fonts/AudiType-Normal.otf') format('opentype');
}

@font-face {
  font-family: 'AudiType-Bold';
  src: local('AudiType-Bold'), url('../fonts/AudiType-Bold.otf') format('opentype');
}

@font-face {
  font-family: 'AudiType-BoldItalic';
  src: local('AudiType-BoldItalic'), url('../fonts/AudiType-BoldItalic.otf') format('opentype');
}

@font-face {
  font-family: 'AudiType-Italic';
  src: local('AudiType-Italic'), url('../fonts/AudiType-Italic.otf') format('opentype');
}
@font-face {
  font-family: 'AudiType-WideBold';
  src: local('AudiType-WideBold'), url('../fonts/AudiType-WideBold.otf') format('opentype');
}
@font-face {
  font-family: 'AudiType-WideLight';
  src: local('AudiType-WideLight'), url('../fonts/AudiType-WideLight.otf') format('opentype');
}

@font-face {
  font-family: 'AudiType-ExtendedNormal';
  src: local('AudiType-ExtendedNormal'),
    url('../fonts/AudiType-ExtendedNormal.otf') format('opentype');
}

@font-face {
  font-family: 'AudiType-ExtendedBold';
  src: local('AudiType-ExtendedBold'), url('../fonts/AudiType-ExtendedBold.otf') format('opentype');
}

@font-face {
  font-family: 'AudiType-ExtendedBoldItalic';
  src: local('AudiType-ExtendedBoldItalic'),
    url('../fonts/AudiType-ExtendedBoldItalic.otf') format('opentype');
}

.font-audi-normal {
  font-family: 'AudiType-Normal', sans-serif;
}

.font-audi-bold {
  font-family: 'AudiType-Bold', sans-serif;
}

.font-audi-bold-italic {
  font-family: 'AudiType-BoldItalic', sans-serif;
}

.font-audi-italic {
  font-family: 'AudiType-Italic', sans-serif;
}

.font-audi-wide-bold {
  font-family: 'AudiType-WideBold', sans-serif;
}

.font-audi-wide-light {
  font-family: 'AudiType-WideLight', sans-serif;
}

.font-audi-nextended-normal {
  font-family: 'AudiType-ExtendedNormal', sans-serif;
}

.font-audi-extended-bold {
  font-family: 'AudiType-ExtendedBold', sans-serif;
}

.font-audi-extended-bold-italic {
  font-family: 'AudiType-ExtendedBoldItalic', sans-serif;
}

.price-options-modal {
  max-width: 720px;

  @media only screen and (max-width: 1000px) {
    top: 15%;
    width: 90vw;
    max-height: 45vh;
  }
}

.price-options-modal-content {
  margin: 10px auto !important;
  padding: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: relative;

  > div {
    padding-left: 0 !important;
    margin-bottom: 10px;
  }

  .CustomButton__button {
    width: 324px;
  }

  .modal-text-container {
    padding-top: 0 !important;
    font-size: 16px;
    text-align: left;
    margin-top: -10px;
    margin-bottom: 14px;
    color: #333333;
  }
}

.simple-pay {
  position: absolute;
  right: 0;
  top: -20px;

  img {
    width: 140px;
    margin-right: 20px;
  }
}

.card {
  height: 152px;
  width: 100%;
  background: #f2f2f2;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  flex-direction: column;
  color: #333333;

  &:hover {
    transform: translateY(-10px);
    box-shadow: 0px 10px 13px -7px #000000, 21px 22px 16px 45px rgba(0, 0, 0, 0);
  }

  .icon-container {
    margin: 12px auto;
  }

  .price {
    font-weight: bold;
    font-size: 16px;
  }
}

.payment-timeout-modal {
    max-width: 372px;
  }
  
  .payment-timeout-modal-content {
    margin: 10px auto !important;
    display: flex;
    justify-content: center;
    text-align: center;
  
    .modal-container {
      margin-right: 28px;
      margin-left: 18px;
      align-items: 'center';
    }
  
    .modal-header-text {
      font-size: 17px;
      margin-top: 14px;
      margin-bottom: 22px;
      color: 'black';
      text-align: left;
    }
  }
  
.Modal {
  &.add-admin-modal {
    max-width: 410px;
  }
  &.delete-admin-modal {
    max-width: 410px;
  }
  &.price-options-modal {
    max-width: 720px;
  }
  &.price-option-confirm-modal {
    max-width: 372px;
  }

  position: absolute;
  display: flex;
  z-index: 2000;
  background-color: #ffffff;
  width: 70vw;
  min-height: 16px;
  max-width: 625px;
  max-height: 75vh;
  padding: 10px;
  left: 0;
  right: 0;
  top: 15%;
  margin: auto;
  animation: 0.5s slide-up;
  &__closeButtonWrapper {
    position: absolute;
    right: 0;
    height: 16px;
    margin-right: 15px;
    z-index: 2000;
    cursor: pointer;
  }
  @media only screen and (max-width: 1000px) {
    top: 15%;
    width: 90vw;
    max-height: 80vh;
    height: 75vh;
    z-index: 9999;
  }
}
.ModalClosing {
  position: absolute;
  display: flex;
  z-index: 20;
  background-color: #ffffff;
  width: 70vw;
  min-height: 16px;
  max-width: 700px;
  max-height: 50vh;
  padding: 10px;
  left: 0;
  right: 0;
  top: 35%;
  margin: auto;
  animation: 0.5s slide-down;
  &.add-admin-modal {
    max-width: 410px;
  }
  &.delete-admin-modal {
    max-width: 410px;
  }
  &__closeButtonWrapper {
    position: absolute;
    right: 0;
    height: 16px;
    margin-right: 15px;
    z-index: 20;
    cursor: pointer;
  }
  @media only screen and (max-width: 1000px) {
    top: 15%;
    width: 90vw;
    max-height: 80vh;
    height: 75vh;
  }
}

@keyframes slide-up {
  0% {
    transform: translateY(100vh);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes slide-down {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(100vh);
  }
}

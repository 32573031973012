.nav-item {
  color: #333333 !important;
  text-decoration: none;
  transition: all 0.3s ease-in-out;

  &.underline {
    border-bottom: #333333 4px solid;
    text-shadow: 0px 0px 1px black;
  }
}

.audi-logo-container {
  display: flex;
  align-items: center;
  height: 100%;

  svg {
    margin: 0 auto;
    display: block;
  }
}

.mobile-header {
  // max-width: 275px;
  margin: 10px auto;
}

.header-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 5;
  background-color: white;
}

.language-picker-wrapper {
  position: absolute;
    top: 16px;
}
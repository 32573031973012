.PdfViewer {
  &__PDFWrapper {
    display: block;
    width: 100%;
    overflow-x: scroll;

    ::-webkit-scrollbar {
      width: 4px;
      height: 4px;
    }
    ::-webkit-scrollbar-track {
      background-color: darkgrey;
    }
    ::-webkit-scrollbar-thumb {
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.8);
    }
  }
  &__PDF {
    display: block;
    width: 100%;
    max-width: 1200px;
    margin-top: 25px;
  }
}

.react-pdf__Page__canvas {
  margin: 0 auto;
  min-height: 100% !important;
}

.pdf-nav {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 25px;
}

.pdf-btn-container {
  display: flex;
  gap: 1rem;
}

.pdf-page-btn {
  background-color: transparent;
  border: 1px solid black;
  padding: 0.75rem 2rem;
  width: 130px;
  font-size: 14px;
  cursor: pointer;

  &:hover {
    background-color: black;
    color: white;
    transition: all 0.2s ease-in-out;
  }
}

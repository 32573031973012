.delete-account-modal {
  max-width: 370px;
}

.delete-account-modal-content {
  width: 320px !important;
  margin: 10px auto !important;
  display: flex;
  justify-content: center;

  > div {
    align-self: center;
    padding-left: 0 !important;
  }

  .CustomButton__button {
    width: 160px;
  }

  .delete-text-container {
    padding-top: 0 !important;
    font-size: 16px;
    text-align: center;
    margin-top: -10px;
    margin-bottom: 5px;
    color: #333333;
  }

  .icon-container {
    max-width: 84px;
    text-align: center;
    align-items: center;
    justify-content: center;
    margin: 32px auto 20px;
  }

  .confirm-buttons {
    > div {
      display: inline-block;
    }

    .button-transparent-background {
      button {
        background-color: transparent;
        border: none;
        color: #666666;
      }
    }
  }
}

@import "../../../assets/styles/variables.scss";

.Checkbox {
    margin: 0;
    display: flex;
    cursor: pointer;
    &__wrapper {
        display: flex;
        align-content: center;
        justify-items: center;
        width: 16px;
        height: 16px;
        padding: 2px;
        border-style: solid;
        border-width: 1.5px;
        border-color: black;
        user-select: none; /* standard syntax */
        -webkit-user-select: none; /* webkit (safari, chrome) browsers */
        -moz-user-select: none; /* mozilla browsers */
        -khtml-user-select: none; /* webkit (konqueror) browsers */
        -ms-user-select: none;
    }
    &__iconWrapper {
        display: flex;
        width: 100%;
        height: 100%;
        opacity: 0;
        transition: all 0.6s ease;
    }
    &__iconWrapperShow {
        display: flex;
        width: 100%;
        height: 100%;
        opacity: 1;
        transition: all 0.6s ease;
    }
    &__wrapperError {
        display: flex;
        align-content: center;
        justify-items: center;
        width: 16px;
        height: 16px;
        padding: 2px;
        border-style: solid;
        border-width: 1.5px;
        border-color: $errorBorder;
        user-select: none; /* standard syntax */
        -webkit-user-select: none; /* webkit (safari, chrome) browsers */
        -moz-user-select: none; /* mozilla browsers */
        -khtml-user-select: none; /* webkit (konqueror) browsers */
        -ms-user-select: none;
    }
    &__hiddenCheckbox {
        -webkit-appearance: none;
        appearance: none;
    }
}
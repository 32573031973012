.custom-price-modal {
  max-width: 370px;
}

.custom-price-modal-content {
  margin: 10px auto !important;
  display: flex;
  justify-content: center;
  text-align: center;

  > div {
    align-self: center;
    padding-left: 0 !important;
  }

  .CustomButton {
    margin-top: 40px;
    width: 160px;
  }

  .modal-text-container {
    padding-top: 0 !important;
    font-size: 16px;
    text-align: left;
    margin-left: 30px;
    margin-top: -10px;
    margin-bottom: -20px;
    color: #333333;
  }

  .hidden-input {
    width: 300px;
    height: 25px;
    position: relative;
    transform: translateY(-25px);
    z-index: 2;
    opacity: 0;
  }
}

.CustomButton {
  &__button {
    width: 300px;
    height: 50px;
    background-color: black;
    color: white;
    border-width: 1px;
    cursor: pointer;
  }
}

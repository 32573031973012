.home-container {
  min-height: 55vh;
  display: flex;
  margin: 0 auto;
  width: 100%;
  align-items: center;
  justify-content: center;
  max-width: 1000px;
}

.card {
  height: 154px;
  width: 100%;
  background: #F2F2F2;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  flex-direction: column;
  color: #333333;
  &:hover {
    transform: translateY(-10px);
    box-shadow: 0px 10px 13px -7px #000000, 21px 22px 16px 45px rgba(0,0,0,0);
  }

  .credit {
    font-size: 26px;
    letter-spacing: 0;
    text-align: center;
    font-weight: bold;
    color: #333333;
  }

  .text {
    font-size: 16px;
    font-weight: bold;
    margin-top: 6px;
  }
  
  .icon {
    width: 40px;
  }
}

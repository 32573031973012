.terms-of-use-page {
  .header-text {
    margin-bottom: 45px;
  }
}

.pdf-container {
  min-height: 90vh;
  width: 100%;
  display: block;
  margin-bottom: 100px;
}

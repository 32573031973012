.filter-options-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 20px 0 40px;

  .filter-option {
    cursor: pointer;
    font-size: 12px;
    color: #00000080;
    margin-right: 8px;

    &.selected {
      border-bottom: 2px solid #000;
      color: #000
    }
  }
}

.transaction-list-container {
  min-height: 60vh;
  width: 100%;
  display: block;
  margin-bottom: 100px;

  .list-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 14px;
    padding-bottom: 18px;
    border-bottom: 1px solid #CECECE4D;

    .list-item-left {
      display: flex;
      flex-direction: row;
      align-items: center;

      .icon-container {
        margin-right: 13px;
      }

      .transaction-title {
        font-size: 12px;
        color: #333333;
        margin-bottom: 4px
      }


      .transaction-date {
        font-size: 12px;
        color: #333333
      }
    }

    .transaction-amount {
      font-size: 16px;
      color: #333333
    }
  }


  .no-record {
    align-self: center;
  }
}

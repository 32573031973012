.Backdrop {
  display: flex;
  position: absolute;
  top: 0;
  background-color: #000000cc;
  z-index: 1000;
  animation: 0.5s fade-in;
  width: 100%;
  height: 100%;
}
.BackdropClosing {
  display: flex;
  position: absolute;
  top: 0;
  background-color: #000000cc;
  z-index: 1000;
  animation: 0.5s fade-out;
  width: 100%;
  height: 100%;
}
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

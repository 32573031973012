.link {
  color: black;
  text-decoration: none;
  cursor: pointer;
  margin-bottom: 10px;
  
  &:hover {
    text-decoration: underline;
  }
}

.delete-account {
  padding-top: 10px;
  cursor: pointer;
} 

.footer-container {
  min-height: 85px;
}
.LoginPage {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  &__loginWrapper {
    display: flex;
    justify-content: space-between;
    background-color: white;
    height: 100vh;
    width: 100vw;
    max-width: 1900px;
    max-height: 1080px;
    @media only screen and (max-width: 1000px) {
      background-color: transparent;
      z-index: 999;
      display: flex;
      width: 100vw;
      justify-content: center;
    }
  }
  &__sideImageWrapper {
    width: 100vh;
    height: 100vh;

    @media only screen and (max-width: 1000px) {
      position: absolute;
      top: 0;
      z-index: -2;
      height: 280px;
      opacity: 0.6;
      overflow: hidden;
    }
  }
  &__sideImage {
    filter: brightness(50%);
    height: 100vh;
    @media only screen and (max-width: 1000px) {
      width: 100vw;
      height: 70vw;
      display: block;
      margin: 0 auto;
    }
  }
}

.fake-input-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
}

.digit-container {
  display: flex;
  height: 55px;
  border-bottom: solid #707070 1px;
  flex-direction: row;
  justify-content: center;
  flex: 1;
  margin-left: 5px;
  margin-right: 5px;
}

.dot {
  font-size: 24px;
  transform: translateY(30px);
  margin-left: 5px;
  margin-right: 5px;
  color: #ffffff;
}

.digit {
  font-size: 24px;
  font-family: 'AudiType-ExtendedBold';
  color: #000;
}

.currency-text {
  font-size: 24px;
  margin-left: 5px;
  transform: translateY(21px);
  font-family: 'AudiType-ExtendedBold';
  color: #000;
}

.flag-icon-container {
    width: 24px;
    height: 24px;
    cursor: pointer;

    .flag-icon {
        height: 100%;
        width: 100%;
    }
}

.flag-icon-blurred {
    opacity: 0.28;
}

.language-picker {
    display: inline-block;
    margin-left: 150px;
}

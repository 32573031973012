.price-option-confirm-modal {
  max-width: 720px;
}

.price-option-confirm-modal-content {
  margin: 10px auto !important;
  display: flex;
  justify-content: center;

  > div {
    align-self: left;
    padding-left: 0 !important;
  }

  .modal-text-container {
    padding-top: 0 !important;
    font-size: 16px;
    text-align: center;
    margin-top: -10px;
    margin-bottom: 5px;
    color: #333333;
  }
}

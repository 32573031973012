.hamburger-react {
  div {
    left: 0 !important;
  }
}

.pt-2rem {
  padding-top: 1.5rem !important;
}

.menu-toggle-text {
  margin-left: -15px;
  font-size: 16px;
  font-weight: 600;
  color: #333333;
}

.menu-item {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  margin: 0 2rem;
  border-bottom: #d8d8d8 solid 1px;
}

.border-bottom-none {
  border-bottom: none;
}

.drawer-item {
  text-decoration: none;
  color: black;
  font-size: 16px;
  font-weight: 600;
}

.drawer-container {
  position: absolute;
  top: 68px;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #f2f2f2;
  z-index: 2;
  animation: 0.6s ease-in-out 0s 1 slideInFromTop;
}

.drawer-container.close {
  animation: 0.6s ease-in-out 0s 1 slideOutToTop;
}

@keyframes slideInFromTop {
  0% {
    transform: translateY(-100%);
    opacity: 0.5;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slideOutToTop {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(-100%);
    opacity: 0.5;
  }
}
